import styled from 'styled-components';
import { colors } from '../../../styles/atoms/colors';
import shadow from '../../../styles/atoms/shadows';
import { atMinWidth } from '../../../styles/atoms/breakpoints';

export const Container = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 100px;
  background: ${colors.base.white};
  ${shadow('lg')}
  ${atMinWidth.xs`
    width: 64px;
    height: 64px;
  `}
  ${atMinWidth.md`
    width: 80px;
    height: 80px;
  `}
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 14px;
    opacity: 1;
    transition: opacity 250ms ease-in-out;
    &:hover {
      opacity: 0.8;
    }
    ${atMinWidth.xs`
        padding: 16px;
    `}
    ${atMinWidth.md`
        padding: 18px;
    `}
  }
`;
